import currency from 'currency.js';

export function formatSum(sum: number): string {
    return currency(sum).format({
        decimal: '.',
        precision: 2,
        fromCents: true,
        symbol: '₽',
        pattern: `# !`,
    });
}
