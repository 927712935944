import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { DateInput } from '@blueprintjs/datetime';
import { Box } from 'reflexbox';
import { ControlGroup } from '@blueprintjs/core';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment-timezone';

import { FormLabel } from '@components/ui/form-label';
import { VisitCom } from '@components/visit-com';
import { Pagination } from '@components/ui/pagination';
import { Select } from '@components/ui/select';

import { Layout } from '@containers/layout';

import { GlobalState } from '@resources/reducers';
import { selectIsAdmin, selectProfile } from '@resources/users/selectors';
import { User } from '@resources/users/_user';
import { Visit } from '@resources/cards/_visit';
import { selectVisits } from '@resources/cards/selectors';
import { visits } from '@resources/cards/actions';
import { CardsPag } from '@resources/pagination/_state';
import { selectCardsPag } from '@resources/pagination/selectors';
import { CardsActions, ReportsActions } from '@resources/actions/_state';
import { selectCardsActions, selectReportsActions } from '@resources/actions/selectors';
import { CardsFilters, ReportsFilters } from '@resources/filters/_state';
import { selectCardsFilters, selectReportsFilters } from '@resources/filters/selectors';
import { DATE_PERIOD, DATE_PERIODS, DATE_PERIOD_LABEL } from '@resources/reports/_period';
import { GraphIncome, GraphOfficeIncome } from '@resources/reports/_graph';
import { selectGraphOfficesIncomes } from '@resources/reports/selectors';
import { VisitsPayload } from '@resources/cards/interfaces';
import { GraphIncomePayload } from '@resources/reports/interfaces';
import { formatSum } from '@resources/helpers/currency';
import { selectOffices } from '@resources/offices/selectors';
import { Office } from '@resources/offices/_office';
import { graphOfficesIncomes } from '@resources/reports/actions';
import { useCallback } from 'react';

type Props = {
    profile: User;
    visits: Visit[];
    cardsPag: CardsPag;
    cardsActions: CardsActions;
    cardsFilters: CardsFilters;
    reportsFilters: ReportsFilters;
    reportsActions: ReportsActions;
    isAdmin: boolean;
    graphOfficesIncomes: GraphOfficeIncome[];
    offices: Office[];
};

export default connect(mapStateToProps)((props: Props) => {
    const dispatch = useDispatch();

    // Income
    const onIncomeDateChange = (date: Date) =>
        onDispatchIncome({
            filters: {
                ...props.reportsFilters.graph_offices_incomes,
                datetime: date ? date.toISOString() : '',
            },
        });

    const onDatePeriodChange = (datePeriod: DATE_PERIOD) =>
        onDispatchIncome({
            filters: {
                ...props.reportsFilters.graph_offices_incomes,
                period: datePeriod,
            },
        });

    // Visits
    const onPage = (page: number) =>
        onDispatchVisits({
            pagination: {
                ...props.cardsPag.visits,
                page,
            },
            filters: props.cardsFilters.visits,
        });

    const onVisitsDateChange = (date: Date) =>
        onDispatchVisits({
            pagination: props.cardsPag.visits,
            filters: {
                ...props.cardsFilters.visits,
                datetime: date ? date.toISOString() : '',
            },
        });

    // --
    const onDispatchVisits = (payload: VisitsPayload) => {
        dispatch(visits(payload));
    };

    const onDispatchIncome = (payload: GraphIncomePayload) => {
        dispatch(graphOfficesIncomes(payload));
    };

    // --
    React.useEffect(() => {
        onDispatchVisits({
            pagination: props.cardsPag.visits,
            filters: {
                ...props.cardsFilters.visits,
                office_id: props.offices?.[0].id || 0,
            },
        });

        onDispatchIncome({
            filters: {
                ...props.reportsFilters.graph_offices_incomes,
                datetime: moment.utc().local().toDate().toISOString(),
            },
        });
    }, []);

    const getOffice = useCallback(
        (officeId: number) => {
            return props.offices.find((office) => office.id === officeId);
        },
        [props.offices],
    );

    const getGraphIncomeTotal = useCallback(
        (income: GraphIncome) => ({
            common: income.amounts.common.reduce((total, item) => item + total, 0),
            card: income.amounts.card.reduce((total, item) => item + total, 0),
            cash: income.amounts.cash.reduce((total, item) => item + total, 0),
        }),
        [props.graphOfficesIncomes],
    );

    return (
        <Layout
            isLoading={
                props.cardsActions.visits.loading ||
                props.reportsActions.action_logs.loading ||
                props.reportsActions.income.loading
            }
            title="Отчеты">
            {props.isAdmin ? (
                <React.Fragment>
                    <Box width={['10%']} style={{ marginBottom: '15px' }}>
                        <ControlGroup>
                            <DateInput
                                value={
                                    props.reportsFilters.graph_offices_incomes.datetime
                                        ? moment
                                              .utc(
                                                  props.reportsFilters.graph_offices_incomes
                                                      .datetime,
                                              )
                                              .local()
                                              .toDate()
                                        : undefined
                                }
                                parseDate={(str) => new Date(str)}
                                formatDate={(date) => date.toLocaleDateString()}
                                placeholder="Дата документа"
                                locale="ru"
                                localeUtils={MomentLocaleUtils}
                                onChange={onIncomeDateChange}
                                inputProps={{ leftIcon: 'calendar' }}
                            />
                            <Select
                                value={props.reportsFilters.graph_offices_incomes.period}
                                style={{ width: '100%' }}
                                items={DATE_PERIODS.map((p) => ({
                                    label: DATE_PERIOD_LABEL[p],
                                    value: p,
                                }))}
                                onChange={onDatePeriodChange}
                                placeholderLabel="- Период -"
                            />
                        </ControlGroup>
                    </Box>

                    {props.graphOfficesIncomes.map((graphOfficeIncome) => {
                        const { common, card, cash } = getGraphIncomeTotal(
                            graphOfficeIncome.income,
                        );
                        return (
                            <Box mb={15} key={graphOfficeIncome.office_id}>
                                <FormLabel>
                                    Выручка (
                                    {getOffice(graphOfficeIncome.office_id)?.name || 'Общее'})
                                </FormLabel>
                                <Bar
                                    data={{
                                        labels: graphOfficeIncome.income.labels,
                                        datasets: [
                                            {
                                                label: 'Итого: ' + formatSum(common),
                                                data: graphOfficeIncome.income.amounts.common,
                                                backgroundColor: '#4ad66d',
                                            },
                                            {
                                                label: 'Карта: ' + formatSum(card),
                                                data: graphOfficeIncome.income.amounts.card,
                                                backgroundColor: '#42a5f5',
                                            },
                                            {
                                                label: 'Наличные: ' + formatSum(cash),
                                                data: graphOfficeIncome.income.amounts.cash,
                                                backgroundColor: '#ff8700',
                                            },
                                        ],
                                    }}
                                    options={{
                                        aspectRatio: 3.5,
                                        layout: {
                                            padding: 10,
                                        },
                                    }}
                                />
                            </Box>
                        );
                    })}

                    <FormLabel>Список оказанных услуг</FormLabel>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '4px' }}>
                        <DateInput
                            value={
                                props.cardsFilters.visits.datetime
                                    ? moment
                                          .utc(props.cardsFilters.visits.datetime)
                                          .local()
                                          .toDate()
                                    : undefined
                            }
                            parseDate={(str) => new Date(str)}
                            formatDate={(date) => date.toLocaleDateString()}
                            placeholder="Дата документа"
                            locale="ru"
                            localeUtils={MomentLocaleUtils}
                            onChange={onVisitsDateChange}
                            inputProps={{ leftIcon: 'calendar' }}
                        />
                        <Select
                            value={String(props.cardsFilters.visits.office_id)}
                            style={{ width: '25%' }}
                            items={props.offices.map((office) => ({
                                label: office.name,
                                value: String(office.id),
                            }))}
                            onChange={(value) => {
                                onDispatchVisits({
                                    pagination: props.cardsPag.visits,
                                    filters: {
                                        ...props.cardsFilters.visits,
                                        office_id: !!value ? Number(value) : 0,
                                    },
                                });
                            }}
                        />
                    </div>

                    <Box mt={15}>
                        <div>
                            {props.visits.map((visit) => (
                                <VisitCom key={visit.id} visit={visit} />
                            ))}
                        </div>
                        <Pagination pag={props.cardsPag.visits} onPage={onPage} />
                    </Box>
                </React.Fragment>
            ) : (
                'Доступ запрещен'
            )}
        </Layout>
    );
});

function mapStateToProps(state: GlobalState): Props {
    return {
        profile: selectProfile(state),
        visits: selectVisits(state),
        cardsPag: selectCardsPag(state),
        cardsActions: selectCardsActions(state),
        cardsFilters: selectCardsFilters(state),
        reportsFilters: selectReportsFilters(state),
        reportsActions: selectReportsActions(state),
        isAdmin: selectIsAdmin(state),
        graphOfficesIncomes: selectGraphOfficesIncomes(state),
        offices: selectOffices(state),
    };
}
