import React from 'react';
import { Flex, Box } from 'reflexbox';
import moment from 'moment-timezone';

import * as styles from './index.module.scss';

import { PAY_METHOD_LABEL, Visit } from '@resources/cards/_visit';
import { FORMAT_DATETIME } from '@resources/helpers/datetime';
import { VisitPrice } from '@resources/cards/_visit-price';

type Props = {
    visit: Visit;
};

export const VisitCom = (props: Props) => {
    const { _prices } = props.visit;

    const formatPrice = (price: VisitPrice): string => {
        return `${price.name} ${price.price} р`;
    };

    return (
        <div className={styles.container}>
            <Flex>
                <Box width={['60%']}>
                    <div className={styles.name}>
                        {props.visit._card.last_name} {props.visit._card.first_name}{' '}
                        {props.visit._card.patronymic}
                    </div>
                    <div className={styles.prices}>
                        {_prices.length ? _prices.map(formatPrice).join(' / ') : '- не найдено -'}
                    </div>
                </Box>
                <Box width={['40%']} className={styles.datetime}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexDirection: 'row',
                            marginBottom: '5px',
                        }}>
                        <div className={styles.sum}>{props.visit.total} р.</div>
                        <div className={styles.method}>
                            ({PAY_METHOD_LABEL[props.visit.pay_method]})
                        </div>
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        Офис: {props.visit._office.name || '-'}
                    </div>
                    <div style={{ color: 'gray' }}>
                        {moment.utc(props.visit.docs_datetime).local().format(FORMAT_DATETIME)}
                    </div>
                </Box>
                <Box width={['20%']}></Box>
            </Flex>
        </div>
    );
};
